import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import YourAdvantagesCard from './YourAdvantagesCard'
import PriceListCard from './PriceListCard'
import BackgroundImage from 'gatsby-background-image'
import usePriceListData from '../../hooks/usePriceListData'
import TarifDetails from './TarifDetails/TarifDetails'

const tarifOptions = ['xs', 's', 'm', 'l']

const PriceListSection = () => {
  const [selectedTarif, setSelectedTarif] = useState(tarifOptions[2])
  const tarifs = usePriceListData()
  const activeTarif = tarifs[selectedTarif]

  return (
    <StaticQuery
      query={graphql`
        query PriceListSection {
          background: imageSharp(original: { src: { regex: "/.*price-list-background.*/" } }) {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      `}
      render={({ background: { fluid: background } }) => (
        <BackgroundImage
          Tag="section"
          fluid={background}
          className="block mx-2 px-2 z-10 shadow-lg bg-white lg:mx-8 lg:px-8 lg:-mt-8"
        >
          <div className="container mx-auto relative">
            <div className="flex flex-wrap justify-center items-stretch -mx-2 lg:-mx-8">
              <GridRow className="order-1">
                <SectionTitle>Deine Vorteile</SectionTitle>
              </GridRow>

              <GridRow id="tarif" className="order-3">
                <SectionTitle>Dein Tarif</SectionTitle>
              </GridRow>

              <GridRow className="order-2">
                <YourAdvantagesCard />
              </GridRow>

              <GridRow className="order-4">
                <PriceListCard
                  selectedTarif={selectedTarif}
                  setSelectedTarif={setSelectedTarif}
                  tarifOptions={tarifOptions}
                  tarifs={tarifs}
                  activeTarif={activeTarif}
                />
              </GridRow>

              <GridRow className="hidden lg:block" />

              <GridRow className="order-5 rounded">
                <nav className="flex justify-center items-center my-6 lg:my-12 text-sm flex-wrap lg:flex-no-wrap">
                  <span className="mx-3 my-3 lg:my-0 font-bold">Weitere Infos:</span>
                  <TarifDetails activeTarif={activeTarif} />
                  <a
                    className="mx-3 underline"
                    target="__blank"
                    href={`https://www.vodafone.de/media/downloads/pdf/VF-${activeTarif.title
                      .trim()
                      .replace(' ', '-')}-Mobil-Mai-2020.pdf`}
                  >
                    Produktinformationsblatt
                  </a>
                </nav>
              </GridRow>
            </div>
          </div>
        </BackgroundImage>
      )}
    />
  )
}

export default PriceListSection

const SectionTitle = ({ children }) => (
  <h2 className="text-2xl font-semibold text-center my-6">{children}</h2>
)

const GridRow = ({ children, className, ...rest }) => (
  <div {...rest} className={`w-full px-0 lg:w-2/5 lg:px-8 lg:order-none ${className}`}>
    {children}
  </div>
)
