import { useStaticQuery, graphql } from 'gatsby'

const usePassOverviewData = () => {
  const { restApiApiPass } = useStaticQuery(graphql`
    {
      restApiApiPass {
        video {
          description
          name
          title
        }
        social {
          description
          name
          title
        }
        music {
          description
          name
          title
        }
        chat {
          description
          name
          title
        }
      }
    }
  `)

  const passKeyArray = ['social', 'chat', 'music', 'video']
  return { ...restApiApiPass, passKeyArray }
}

export default usePassOverviewData
