import React from 'react'
import usePassOverviewBackgrounds from './usePassOverviewBackgrounds'
import usePassData from '../../hooks/usePassData'
import useArrowUp from './useArrowUp'
import BackgroundImage from 'gatsby-background-image'
import PassCard from './PassCard'
import Img from 'gatsby-image/withIEPolyfill'

const PassOverview = () => {
  const { background, ...cardBackgrounds } = usePassOverviewBackgrounds()
  const arrowUp = useArrowUp()
  const { passKeyArray, ...socialData } = usePassData()
  return (
    <BackgroundImage
      Tag="section"
      className="lg:-mt-16 -mt-8 flex flex-col justify-center items-center"
      fluid={background}
      style={{ backgroundSize: 'cover' }}
    >
      <div className="container mx-auto lg:mt-32 mt-16 text-center text-white px-4 relative">
        <h2 className="text-3xl font-bold">Deine Lieblings-Apps ohne Datenverbrauch</h2>
        <p className="max-w-xl mx-auto mt-8 lg:mb-16 mb-4">
          Im Checkout-Prozess kannst Du einen kostenlosen Pass zu Deinem Tarif auswählen. Weitere Pässe sind gegen Aufpreis in der App zubuchbar.
        </p>

        <div className="flex lg:flex-no-wrap flex-wrap -mx-4 mb-32">
          {passKeyArray.map((key, i) => {
            return (
              <PassCard key={key} bgImg={cardBackgrounds[key]} data={socialData[key]} index={i} />
            )
          })}
        </div>

        <div className="flex lg:justify-end justify-center -mx-4">
          <button
            type="button"
            className="text-sm bg-secondary-black p-6 transform translate-y-1/3 flex flex-col items-center"
            onClick={(e) => {
              e.preventDefault()
              document.getElementById('tarif').scrollIntoView({ behavior: 'smooth' })
            }}
          >
            <Img fixed={arrowUp} className="mb-3" />
            Zurück zum Tarif
          </button>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default PassOverview
