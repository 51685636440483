import React, { useState, useRef } from 'react'
import styles from './TarifDetails.module.css'
import TarifAdvantages from './TarifAdvantages'
import FaqSection from '../../FaqSection/index'
import CheckmarkIcon from '../../icons/CheckmarkRegular'
import TimesIcon from '../../icons/Times'
import useCloseDialogHandler from './useCloseDialogHandler'

const TarifDetails = ({ activeTarif: { title, new_volume, volume } }) => {
  const details = useRef(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useCloseDialogHandler({ isModalOpen, setIsModalOpen })

  return (
    <details
      ref={details}
      open={isModalOpen}
      onToggle={() => {
        if (details.current.open) {
          document.body.style.overflowY = 'hidden'
          setIsModalOpen(true)
        } else {
          document.body.style.overflowY = 'auto'
          setIsModalOpen(false)
        }
      }}
    >
      <summary
        className={`${styles.tarifDetailsSummary} list-none mx-3 underline focus:font-bold focus:outline-none cursor-pointer`}
      >
        Tarifdetails
      </summary>
      <aside>
        <div
          className={`${styles.tarifDetailsAside} fixed max-w-5xl lg:w-2/3 w-full p-6 bg-white top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 flex flex-wrap overflow-y-auto shadow-md font-thin z-20`}
        >
          <div className="w-full flex justify-between items-center">
            <h2 className="text-primary text-2xl lg:text-4xl font-normal mt-4 mb-8">
              {title} mit {new_volume ?? volume} GB Datenvolumen
            </h2>
            <button className="lg:mr-4 mr-0" type="button" onClick={() => setIsModalOpen(false)}>
              <TimesIcon className="w-8 h-8" />
            </button>
          </div>
          <TarifAdvantages />
          <h3 className="text-2xl w-full mt-6 mb-4">Aktuelle Aktionen</h3>
          <ul className="w-full">
            {[
              '12 Monate x 10 € Rabatt',
              ...[
                new_volume ? `Jetzt ${new_volume} GB statt ${volume} GB Datenvolumen` : null,
                'Versand geschenkt',
              ],
            ].map(
              (action) =>
                action && (
                  <li key={action} className="flex items-baseline my-2">
                    <CheckmarkIcon className="text-green-600 mr-2" width="1rem" height="1rem" />
                    <strong>Aktion:&nbsp;</strong>
                    {action}
                  </li>
                )
            )}
          </ul>
          <FaqSection id="faq-section-modal" />
        </div>
        <Dimmer onClick={() => setIsModalOpen(false)} />
      </aside>
    </details>
  )
}

export default TarifDetails

const Dimmer = (attrs) => (
  <div {...attrs} className="fixed z-10 top-0 left-0 w-screen h-screen bg-black bg-opacity-50" />
)
