import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import { numberList } from './YourAdvantagesCard.module.css'

const YourAdvantagesCard = () => {
  const [showSteps, setShowSteps] = useState(true)
  return (
    <div className=" bg-primary text-white rounded border border-gray-500 shadow w-full h-full p-4">
      <div className="flex bg-white text-primary p-2 py-3 rounded">
        <AdvantagesButton active={showSteps} onClick={() => setShowSteps(true)}>
          Übersicht
        </AdvantagesButton>
        <AdvantagesButton active={!showSteps} onClick={() => setShowSteps(false)}>
          Mehr Infos zum Gutschein
        </AdvantagesButton>
      </div>
      {showSteps ? <AdvantagesOverview /> : <AdvantagesAmazon />}
    </div>
  )
}

export default YourAdvantagesCard

const AdvantagesButton = ({ children, active, ...props }) => {
  const conditionalClasses = active ? 'text-white bg-primary' : 'text-primary bg-white'
  return (
    <button
      className={`${conditionalClasses} rounded border border-gray-400 text-center px-4 py-6 flex-1 mx-2 font-semibold`}
      {...props}
    >
      {children}
    </button>
  )
}

const AdvantagesAmazon = () => {
  const steps = [
    'Tarif abschließen',
    '60-tägige Frist abwarten',
    'Email mit Gutscheincode erhalten',
    'Gutscheincode bis zum 31.12.2021 bei nextbike einlösen',
    'Losfahren!',
    'Fragen zum Gutschein unter folgender E-Mail: vodafone@jcc.group',
  ]
  return (
    <>
      <h3 className="my-8 text-xl font-semibold leading-tight">
        So erhältst Du
        <br />
        Deinen Gutschein von nextbike
      </h3>
      <ol className={`${numberList}`}>
        {steps.map((step) => (
          <li key={step}>{step}</li>
        ))}
      </ol>
    </>
  )
}

const AdvantagesOverview = () => (
  <StaticQuery
    query={graphql`
      query AmazonInfos {
        checkMark: imageSharp(original: { src: { regex: "/.*checkmark.*/" } }) {
          fixed(pngQuality: 8, width: 21) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
        amazonCard: imageSharp(original: { src: { regex: "/.*nextbike.*/" } }) {
          fixed(pngQuality: 8, width: 137) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    `}
    render={({ checkMark: { fixed: checkMark }, amazonCard: { fixed: amazonCard } }) => {
      const infos = [
        {
          title: 'Gutschein von nextbike im Wert von bis zu 60€',
          text: `Du erhältst einen Gutschein im Wert von bis zu 60€<a className="inline-block font-bold underline text-sm" href="#footnote-1"><sup>1</sup></a>.`,
        },
        {
          title: 'Gaming Pass und ein weiterer Vodafone Pass inklusive',
          text: ``,
        },
        {
          title: 'Highspeed-Internet',
          text: `Du surfst im 4G|LTE- und 5G-Netz<a className="inline-block font-bold underline text-sm" href="#footnote-3"><sup>3</sup> inkl. SpeedGo<a className="inline-block font-bold underline text-sm" href="#footnote-2"><sup>2</sup>.`,
        },
        { title: 'Telefon- und SMS-Flat', text: 'In alle deutschen Netze.' },
      ]
      return (
        <ul className="my-8">
          {infos.map(({ title, text }, i) => (
            <li key={title} className="flex items-baseline my-4">
              <Img fixed={checkMark} />
              <div className="ml-2">
                <h4 className="text-xl font-semibold mb-2">{title}</h4>
                <p dangerouslySetInnerHTML={{ __html: text }} />
                {i === 0 && (
                  <figure className="mt-2">
                    <Img fixed={amazonCard} />
                  </figure>
                )}
              </div>
            </li>
          ))}
        </ul>
      )
    }}
  />
)
