import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'

const PassCard = ({ bgImg, data: { title, description }, index }) => {
  const dynamicClasses =
    index % 2
      ? 'text-right mr-auto pl-8 lg:pl-6  pr-20 lg:pr-12 xl:pr-20'
      : 'text-left ml-auto pr-8 lg:pr-6 xl:pl-20 lg:pl-16 pl-24'
  return (
    <div className="xl:1/4 sm:w-1/2 w-full px-4 my-4 xl:my-0">
      <div className="bg-primary-dark bg-opacity-80 h-full xl:max-w-none max-w-sm mx-auto relative">
        <article
          className="flex flex-col justify-center items-center"
          style={{ paddingTop: '162.389%' }}
        >
          <Img
            fluid={bgImg}
            objectFit="fill"
            style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }}
          />
          <div
            className={`${dynamicClasses}${
              index === 3 ? ' -mt-4 ' : ''
            } sm:text-sm xl:py-40 lg:py-32 text-lg pl-3 w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2`}
          >
            <h3 className="font-bold w-full">{title}</h3>
            <p>{description}</p>
          </div>
        </article>
      </div>
    </div>
  )
}

export default PassCard
