import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { greenTriangle } from './PriceListCard.module.css'

const deNumberFormat = new Intl.NumberFormat('de-DE')

const PriceListCard = ({ selectedTarif, setSelectedTarif, tarifOptions, tarifs, activeTarif }) => {
  const [showAllTarifs, setShowAllTarifs] = useState(false)

  return (
    <div className="bg-gray-300 rounded shadow w-full h-full p-4 text-lg border border-gray-500">
      <div className="flex rounded-lg">
        {showAllTarifs ? (
          tarifOptions.map((tarif, i) => (
            <TarifButton
              key={i}
              index={i}
              active={selectedTarif === tarifOptions[i]}
              tarif={tarifs[tarif]}
              onClick={() => setSelectedTarif(tarifOptions[i])}
            />
          ))
        ) : (
          <TarifButton index={2} active={true} tarif={tarifs['m']} singleButton />
        )}
      </div>
      <div className="flex justify-between mt-8">
        <div>
          <h3 className="font-bold">
            {activeTarif.title} mit {activeTarif.new_volume || activeTarif.volume} GB{' '}
            {activeTarif.new_volume && `statt ${activeTarif.volume} GB`}
            <button
              type="button"
              className="inline font-bold"
              onClick={() => {
                document.getElementById('faq-section').scrollIntoView({ behavior: 'smooth' })
              }}
            >
              <sup>3</sup>
            </button>
          </h3>
        </div>
        <figure className="flex items-center justify-center bg-white rounded-full p-4 w-28 h-28">
          <h4 className="font-bold text-center text-3xl text-primary">
            {activeTarif.promotion_voucher_value}€
            <br />
            <span className="block font-normal text-xs leading-none">
              nextbike-
              <br />
              Gutschein
            </span>
          </h4>
        </figure>
      </div>

      <EmphasizedNewPrice price={activeTarif.new_price} className="my-4" />

      <p className="text-sm mt-8 mb-4">
          Ab dem 13. Monat {activeTarif.price}€. Dazu {activeTarif.connection_price}€ Anschlusspreis.
        <br />
        24 Monate Mindestlaufzeit.
      </p>

      <button
        className="block mb-12 py-2 w-2/3 text-center font-bold text-sm bg-secondary-black text-white"
        onClick={(e) => {
          e.preventDefault()
          localStorage.setItem('tarif', JSON.stringify(activeTarif))
          navigate('/order', { state: { tarif: activeTarif } })
        }}
      >
        Jetzt bestellen
      </button>

      <p className="text-sm leading-none">
        {showAllTarifs ? 'Zu viel Auswahl?' : 'Du brauchst mehr Auswahl?'}
      </p>
      <button
        className="text-sm leading-none text-primary font-bold underline"
        onClick={() => {
          setShowAllTarifs(!showAllTarifs)
          setSelectedTarif('m')
        }}
      >
        {showAllTarifs ? 'Weniger Tarife anzeigen' : 'Mehr Tarife anzeigen'}
      </button>
    </div>
  )
}

export default PriceListCard

const TarifButton = ({ children, index, active, tarif, singleButton = false, ...props }) => {
  const border = index === 0 ? 'border-l-2' : 'border-l-0'
  const classesByActiveState = active ? 'bg-primary text-white' : 'text-black bg-white'
  const width = singleButton ? 'w-full' : 'w-1/4'
  return (
    <button
      className={`${border} ${classesByActiveState} ${width} border-2 border-gray-400 py-6 cursor-pointer relative text-base`}
      type="button"
      {...props}
    >
      {!singleButton ? (
        <>
          <h3 className="font-bold text-xl leading-none mb-1">
            {tarif.new_volume || tarif.volume} GB
          </h3>
          {!!tarif.new_volume && (
            <p className={`leading-none${active ? '' : ' text-secondary-black'}`}>
              statt {tarif.volume} GB
            </p>
          )}
          {index === 2 && <Banner />}
        </>
      ) : (
        <h3 className="text-xl leading-loose">
          <strong>{tarif.title}</strong> mit <strong>{tarif.new_volume} GB</strong> Datenvolumen
        </h3>
      )}
    </button>
  )
}

const Banner = () => (
  <figure className="absolute z-10 top-0 left-1/2 transform -translate-y-1/2 -translate-x-1/2 min-w-13/12 py-1 px-2 rounded-br bg-green-500 text-white font-bold whitespace-pre text-xxs">
    nextbike empfiehlt
    <div className={greenTriangle} />
  </figure>
)

const EmphasizedNewPrice = ({ price, className }) => {
  const [integer, fractional] = price.toString().split('.')
  return (
    <h3 className={`flex text-6xl font-bold${className ? ` ${className}` : ''}`}>
      <span className="leading-none">{integer}</span>
      <span className="flex flex-col text-5xl leaing-none">
        <span className="leading-none">{fractional}€</span>
        <span className="block -mt-1 font-normal text-xs leading-none">pro Monat</span>
      </span>
    </h3>
  )
}
