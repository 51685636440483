import React from 'react'

const TarifAdvantages = () => {
  return (
    <>
      <h3 className="w-full text-gray-600 text-xl lg:text-2xl">Deine Vorteile</h3>
      <TarifDetailRow
        viewBox="-324 202.3 192 192"
        title="Vodafone Pass kostenlos zubuchbar"
        paragraph={`Mit Deinem Vodafone Pass nutzt Du teilnehmende Apps, ohne Dein Datenvolumen zu verbrauchen. Du hast 4 Pässe zur Auswahl: Chat-, Social-, Music- oder Video-Pass. Den ersten Pass kannst Du&nbsp;kostenlos zubuchen. Die teilnehmenden Apps je Pass findest Du auf <a href="https://www.vodafone.de/privat/service/vodafone-pass.html" target="_blank" class="underline">vodafone.de/pass</a>. Du kannst in der MeinVodafone-App Deinen kostenlosen Pass auswählen, jeden Monat wechseln oder weitere Pässe buchen.`}
      >
        <path d="M-277.2 378.3c-.8 0-1.6-.2-2.3-.8-1.4-1-2-2.8-1.5-4.4l17.4-55.6-43-36.2c-1.3-1.1-1.8-2.9-1.2-4.4s2.1-2.6 3.8-2.6h53.8l18.4-53.3c.6-1.6 2.1-2.7 3.8-2.7 1.7 0 3.2 1.1 3.8 2.8l17.4 53.2h54.8c1.7 0 3.2 1.1 3.8 2.7.6 1.6.1 3.4-1.2 4.4l-43.9 36.1L-175 373c.5 1.6 0 3.5-1.4 4.5s-3.3 1-4.7 0l-46.8-34-46.8 34c-.8.5-1.7.8-2.5.8zm-15.8-96l36.7 30.8c1.2 1 1.7 2.7 1.2 4.3l-14.6 46.6 39.4-28.6c1.4-1 3.3-1 4.7 0l39.1 28.4-15.3-46.3c-.5-1.6 0-3.3 1.3-4.3l37.4-30.8h-46.5c-1.7 0-3.3-1.1-3.8-2.8l-14.6-44.7-15.4 44.7c-.6 1.6-2.1 2.7-3.8 2.7H-293z" />
      </TarifDetailRow>

      <TarifDetailRow
        title="Highspeed-Internet im 4GILTE- und 5G-Netz"
        paragraph={`Du surfst automatisch mit der maximal verfügbaren Geschwindigkeit im 4G|LTE-und 5G-Netz. Mit Deinem Red-Tarif nutzt Du künftig im Inland die neue 5G-Netz-Technologie. Die ersten Stationen sind jetzt aktiv – in ausgewählten Städten. Danach geht's schrittweise weiter. Um das 5G-Netz nutzen zu können, brauchst Du ein 5G-fähiges Gerät.`}
      >
        <path d="M70 48.5h-7.1c-1.1 0-2 .9-2 2s.9 2 2 2H68v4.1c0 1.3-.8 2.4-2.1 2.8-2.1.6-5.1.9-7.1-.6s-3-4.6-3-9.3c0-5.4 2-8.9 5.5-9.7 3-.7 5.7.6 6.1 1.3.5 1 1.8 1.4 2.7.8 1-.5 1.4-1.8.8-2.7-1.5-2.9-6.4-4.4-10.6-3.4-2.6.6-8.6 3.3-8.6 13.7 0 6 1.5 10.2 4.6 12.5 1.7 1.3 3.8 2 6.2 2 1.4 0 2.9-.2 4.4-.6 3-.8 5.1-3.6 5-6.8v-6c.1-1.2-.8-2.1-1.9-2.1z"></path>
        <path d="M49.7 9C27.4 9 9.2 27.2 9.2 49.5S27.4 90 49.7 90s40.5-18.2 40.5-40.5S72.1 9 49.7 9zm0 76.9c-20.1 0-36.4-16.3-36.4-36.4 0-20.1 16.3-36.4 36.4-36.4s36.4 16.3 36.4 36.4c.1 20.1-16.3 36.4-36.4 36.4z"></path>
        <path d="M38 45.1h-1.8l-1.5.2v-5.9h9c1.1 0 2-.9 2-2s-.9-2-2-2h-11c-1.1 0-2 .9-2 2v10.2c0 .6.2 1.1.7 1.5.4.4 1 .6 1.6.5l3.7-.4h1.1c4.3.3 4.9 3.7 4.9 5.6 0 3-2.3 5.1-5.6 5.1-1.7 0-3.4-.5-4.9-1.4-1-.6-2.2-.3-2.8.7-.6 1-.3 2.2.7 2.8 2.1 1.3 4.5 2 7 2 5.5 0 9.7-3.9 9.7-9.1-.1-5.6-3.5-9.4-8.8-9.8z"></path>
      </TarifDetailRow>

      <TarifDetailRow
        title="GigaDepot"
        paragraph={`Du nimmst Dein unverbrauchtes Datenvolumen mit in den nächsten Monat. Automatisch und kostenlos.`}
      >
        <path d="M63.806 8.484a2.083 2.083 0 0 0-1.306 1.933v10.417H35.417a2.083 2.083 0 0 0-2.083 2.083v13.745L8.914 62.099a2.083 2.083 0 0 0 0 2.885l25 26.042a2.086 2.086 0 0 0 3.587-1.443V79.167h27.083a2.083 2.083 0 0 0 2.083-2.083V63.338l24.42-25.437c.387-.403.58-.923.58-1.443s-.193-1.04-.58-1.443l-25-26.042a2.086 2.086 0 0 0-2.281-.489zM33.333 77.083v7.322L13.305 63.542l20.029-20.863V50c0 1.151.933 2.083 2.083 2.083H62.5V75H35.417a2.084 2.084 0 0 0-2.084 2.083zm53.362-40.625L66.667 57.322V50a2.083 2.083 0 0 0-2.083-2.083H37.5V25h27.083a2.083 2.083 0 0 0 2.083-2.083v-7.322l20.029 20.863z" />
      </TarifDetailRow>

      <TarifDetailRow
        title="Telefon- und SMS-Flat"
        paragraph={`Du telefonierst und simst unbegrenzt in alle deutschen Mobilfunk-Netze. Und natürlich ins Festnetz.`}
      >
        <path d="M56.043 82.775H43.957a2.273 2.273 0 0 1 0-4.546h12.087a2.273 2.273 0 1 1-.001 4.546z"></path>
        <path d="M66.116 90.909H33.884c-4.585 0-8.316-3.756-8.316-8.373V17.464c0-4.617 3.731-8.373 8.316-8.373h32.232c4.585 0 8.316 3.756 8.316 8.373v65.072c0 4.617-3.731 8.373-8.316 8.373zM33.884 13.636c-2.079 0-3.771 1.717-3.771 3.828v65.072c0 2.111 1.692 3.828 3.771 3.828h32.232c2.079 0 3.771-1.717 3.771-3.828V17.464c0-2.111-1.692-3.828-3.771-3.828H33.884z"></path>
        <path d="M57.758 52.273c-3.53 0-6.223-2.717-7.746-4.755-1.447 2.048-4.06 4.755-7.765 4.755-4.995 0-8.62-3.949-8.62-9.39s3.625-9.39 8.62-9.39c3.726 0 6.348 2.799 7.776 4.863 1.547-2.039 4.309-4.863 7.736-4.863 4.993 0 8.616 3.949 8.616 9.39s-3.624 9.39-8.617 9.39zm-5.267-9.349l.188.374c.596 1.114 2.864 4.429 5.079 4.429 2.812 0 4.071-2.433 4.071-4.845 0-2.411-1.259-4.845-4.071-4.845-1.882 0-4.242 3.04-5.07 4.483l-.197.404zm-10.245-4.886c-2.815 0-4.075 2.433-4.075 4.845 0 2.411 1.26 4.845 4.075 4.845 2.849 0 4.934-4.291 4.954-4.335l.218-.446-.279-.56c-.017-.037-2.09-4.349-4.893-4.349z"></path>
      </TarifDetailRow>

      <TarifDetailRow
        title="Nie ohne Datenvolumen mit SpeedGo"
        paragraph={`Wenn Du Dein Datenvolumen und Dein GigaDepot doch mal vollständig aufgebraucht hast: Wir schalten Dir bis zu 3-mal hintereinander Datenvolumen-Pakete frei – zu 3 Euro je 250 MB. Vor der kostenpflichtigen Zubuchung bekommst Du immer eine Info-SMS. Und Du kannst jederzeit per SMS ablehnen.`}
      >
        <path d="M87.5 14.6c0-.6-.2-1.1-.6-1.5-.4-.4-.9-.6-1.5-.6-21.7.1-35.2 9.8-43.1 19l-13.7 4c-.3.1-.6.3-.9.5l-6.3 6.3c-.4.4-.6.9-.6 1.5s.2 1.1.6 1.5l8.5 8.5c-.5 1.8-.8 2.8-.8 2.9-.1.6.2 1.2.6 1.6l11.5 12c.4.4.9.6 1.5.6h.4c.1 0 1.3-.3 3.1-.8l8.6 8.6c.4.4.9.6 1.5.6s1.1-.2 1.5-.6l6.3-6.3c.2-.2.4-.6.5-.9l4-13.7c9.2-8 18.8-21.5 18.9-43.2zM25.9 43.7l4.4-4.4L38 37c-3.1 4.5-5.2 8.8-6.5 12.3l-5.6-5.6zm7.7 12.5c.8-2.8 3.6-11.4 10.1-19.8 9.7-12.5 23-19.1 39.6-19.6-.5 15.9-6.6 28.7-17.9 38.2-.1 0-.1.1-.1.1-.8.6-1.6 1.3-2.4 1.9-8.2 6.1-16.6 8.8-19.5 9.5l-9.8-10.3zm22.7 17.9l-5.7-5.7C54 67.1 58.4 65 62.9 62l-2.3 7.8-4.3 4.3zM36 73.5l-6.2 6.2c-.8.8-.8 2.1 0 2.9.4.4.9.6 1.5.6s1.1-.2 1.5-.6l6.2-6.2c.8-.8.8-2.1 0-2.9-.8-.8-2.2-.8-3 0zM18.8 70.8c.5 0 1.1-.2 1.5-.6l6.2-6.2c.8-.8.8-2.1 0-2.9-.8-.8-2.1-.8-2.9 0l-6.2 6.2c-.8.8-.8 2.1 0 2.9.3.4.8.6 1.4.6zM32.7 67.3c-.8-.8-2.1-.8-2.9 0L13.1 83.9c-.8.8-.8 2.1 0 2.9.4.4.9.6 1.5.6.5 0 1.1-.2 1.5-.6l16.7-16.7c.7-.7.7-2-.1-2.8z" />
        <path d="M66.7 41.7c2.2 0 4.3-.9 5.9-2.5 1.6-1.6 2.4-3.7 2.4-5.9 0-4.6-3.7-8.4-8.3-8.4-2.2 0-4.3.9-5.9 2.5-1.6 1.6-2.4 3.7-2.4 5.9 0 4.6 3.7 8.4 8.3 8.4zm-4.2-8.4c0-1.1.4-2.2 1.2-3 .8-.8 1.8-1.2 2.9-1.2 2.3 0 4.1 1.9 4.1 4.2 0 1.1-.4 2.2-1.2 3-.8.8-1.8 1.2-2.9 1.2-2.2 0-4.1-1.9-4.1-4.2z" />
      </TarifDetailRow>

      <TarifDetailRow
        title="EU-Roaming"
        paragraph={`Auch wenn Du im EU-Ausland bist, surfst Du kostenlos mit dem Datenvolumen Deines Tarifs. Außerdem telefonierst und simst Du dort und nach Deutschland ohne zusätzliche Kosten. Auch eingehende Anrufe innerhalb der EU kosten Dich nichts.`}
      >
        <path d="M90.834 28.413a15.668 15.668 0 0 0-14.594-2.256l-16.484 5.841-19.452-10.397a7.325 7.325 0 0 0-5.644-.37l-4.881 1.7a2.08 2.08 0 0 0-.798 3.422l12.071 12.264-13.537 4.793-9.663-5.177c-1.575-.762-3.351-.865-5.128-.242l-3.119 1.315a2.08 2.08 0 0 0-.805 3.227l6.774 8.338c4.071 4.879 9.475 7.463 15.217 7.463 2.219 0 4.491-.387 6.754-1.176L81.58 41.465A15.658 15.658 0 0 0 91.6 30.601a2.083 2.083 0 0 0-.766-2.188zm-10.636 9.13L36.161 53.237c-6.401 2.233-12.904.352-17.378-5.008l-5.012-6.17.444-.188c.603-.21 1.252-.173 1.749.067l10.397 5.572a2.083 2.083 0 0 0 1.676.128l16.233-5.75 2.229 2.264c.406.413.944.62 1.481.62a2.08 2.08 0 0 0 1.482-3.537L34.248 25.778l1.775-.618c.79-.273 1.671-.215 2.369.134L63.53 38.731a2.08 2.08 0 1 0 1.96-3.667l-.771-.412 12.9-4.571a11.502 11.502 0 0 1 9.499.86 11.514 11.514 0 0 1-6.92 6.602zm5.158 39.539H14.66a2.077 2.077 0 0 1 0-4.154h70.696a2.077 2.077 0 0 1 0 4.154z"></path>
      </TarifDetailRow>
    </>
  )
}

export default TarifAdvantages

const TarifDetailRow = ({ viewBox = '0 0 100 100', title, paragraph, children }) => {
  return (
    <article className="lg:w-1/2 w-full flex items-center px-2 my-4">
      <div className="pr-4">
        <svg className="h-12 w-12" viewBox={viewBox}>
          {children}
        </svg>
      </div>
      <div>
        <h3 className="font-bold">{title}</h3>
        <p dangerouslySetInnerHTML={{ __html: paragraph }} />
      </div>
    </article>
  )
}
