import { useStaticQuery, graphql } from 'gatsby'

const usePassOverviewBackground = () => {
  const {
    chat: { fluid: chat },
    music: { fluid: music },
    social: { fluid: social },
    video: { fluid: video },
    background: { fluid: background },
  } = useStaticQuery(graphql`
    {
      chat: imageSharp(original: { src: { regex: "/.*chat-pass.*/" } }) {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      music: imageSharp(original: { src: { regex: "/.*music-pass.*/" } }) {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      social: imageSharp(original: { src: { regex: "/.*social-pass.*/" } }) {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      video: imageSharp(original: { src: { regex: "/.*video-pass.*/" } }) {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }

      background: imageSharp(original: { src: { regex: "/.*hero-2-background.*/" } }) {
        fluid(maxWidth: 1920, traceSVG: { background: "#c80c0c", color: "#e30100" }) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  `)

  return { chat, music, social, video, background }
}

export default usePassOverviewBackground
