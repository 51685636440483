import React from 'react'
import DefaultLayout from '../layouts/defaultLayout'
import MainHeader from '../components/MainHeader/'
import PriceListSection from '../components/PriceList/PriceListSection'
import PassOverview from '../components/PassOverview/'
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import Cookies from 'universal-cookie';

export default function Home() {
  const location = useLocation();
  const params = queryString.parse(location.search);

  if (params.s_id) {
    let cookies = new Cookies();
    cookies.set('s_id', params.s_id, { path: '/' });
    console.log(cookies.get('s_id'));
  }

  return (
    <DefaultLayout>
      <MainHeader />
      <PriceListSection />
      <PassOverview />
    </DefaultLayout>
  )
}
