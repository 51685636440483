import { useStaticQuery, graphql } from 'gatsby'

const useArrowUp = () => {
  const {
    arrowUp: { fixed: arrowUp },
  } = useStaticQuery(graphql`
    {
      arrowUp: imageSharp(original: { src: { regex: "/.*arrow-up.*/" } }) {
        fixed(quality: 9) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  `)

  return arrowUp
}

export default useArrowUp
