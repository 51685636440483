import { useStaticQuery, graphql } from 'gatsby'

const useHeaderData = () => {
  const {
    headerImage: { fluid: headerImage },
    increasingSquares: { fluid: increasingSquares },
  } = useStaticQuery(graphql`
    {
      headerImage: imageSharp(original: { src: { regex: "/^.*hero-1((?!background).)*$/" } }) {
        fluid(maxWidth: 2600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      increasingSquares: imageSharp(
        original: { src: { regex: "/.*header-img-increasing-arrow-red.*/" } }
      ) {
        fluid(maxWidth: 450, traceSVG: { background: "#c80c0c", color: "#e30100" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  `)

  return { headerImage, increasingSquares }
}

export default useHeaderData
