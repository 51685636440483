import { useCallback } from 'react'
import useEventListener from '../../../hooks/useEventListener'

const useCloseDialogHandler = ({ setIsModalOpen, isModalOpen }) => {
  const closeModal = useCallback(
    (e) => {
      e = e || window.event
      if (e.key === 'Escape' && isModalOpen) setIsModalOpen(false)
    },
    [setIsModalOpen, isModalOpen]
  )

  useEventListener('keydown', closeModal)
}

export default useCloseDialogHandler
