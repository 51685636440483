import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import useHeaderData from './useHeaderData'

const MainHeader = () => {
  const titleText = 'Für alle unter 28 Jahren!'
  const listTexts = [
    'Doppeltes Datenvolumen',
    'Deine Lieblings-Apps ohne Datenverbrauch',
    'Exklusiv – nextbike Gutschein im Wert von bis zu 60€',
  ]
  const { headerImage, increasingSquares } = useHeaderData()
  return (
    <>
      <Img
        className="max-h-header hidden sm:block"
        fluid={headerImage}
        alt="Group of young people holding a phone"
      />
      <div className="max-w-full w-full sm:w-112 mx-auto sm:mx-0 static top-0 right-0 text-white bg-primary sm:absolute sm:mr-8">
        <div className="relative">
          <div className="relative z-10 p-8 pt-6">
            <h2 className="text-2xl font-bold">{titleText}</h2>
            <ul className="arrow-list ml-8 text-lg">
              {listTexts.map((text, i) => (
                <li key={text} className={i === listTexts.length - 1 ? 'font-bold' : undefined}>
                  {text}
                </li>
              ))}
            </ul>
          </div>
          <Img
            fluid={increasingSquares}
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              zIndex: 1,
              boxShadow: 'black 0px 5rem 5rem -5rem inset',
            }}
          />
        </div>
      </div>
    </>
  )
}

export default MainHeader
