import { useStaticQuery, graphql } from 'gatsby'

const usePriceListData = () => {
  const { restApiApiRate } = useStaticQuery(graphql`
    {
      restApiApiRate {
        xs {
          title
          price
          new_price
          connection_price
          volume
          promotion_voucher_value
        }
        s {
          title
          price
          new_price
          connection_price
          volume
          promotion_voucher_value
          new_volume
        }
        m {
          title
          price
          new_price
          connection_price
          volume
          promotion_voucher_value
          new_volume
        }
        l {
          title
          price
          new_price
          connection_price
          volume
          promotion_voucher_value
          new_volume
        }
      }
    }
  `)

  restApiApiRate.xs.promotion_voucher_value = 30;
  restApiApiRate.s.promotion_voucher_value = 30;
  restApiApiRate.m.promotion_voucher_value = 60;
  restApiApiRate.l.promotion_voucher_value = 60;

  restApiApiRate.xs.new_price = 14.99;
  restApiApiRate.s.new_price = 19.99;
  restApiApiRate.m.new_price = 19.99;
  restApiApiRate.l.new_price = 24.99;

  restApiApiRate.xs.price = 19.99;
  restApiApiRate.s.price = 24.99;
  restApiApiRate.m.price = 29.99;
  restApiApiRate.l.price = 37.99;

  return restApiApiRate
}

export default usePriceListData
